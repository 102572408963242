<template>
  <div>
    <div
      style="width: 50%; position: absolute; z-index: 99"
      @click="closseDoctorSelect"
    >
      &nbsp;
    </div>
    <van-dropdown-menu :overlay="false" active-color="#1691fe">
      <van-dropdown-item disabled title="选择科室" />
      <DoctorSelect ref="doctorSelect" />
    </van-dropdown-menu>
    <div
      style="
        width: 95%;
        overflow: hidden;
        border-radius: 10px;
        margin: 0.4rem auto auto;
      "
    >
      <DeptTree :type="1" @getDept="getDept" />
    </div>
  </div>
</template>

<script>
  import DeptTree from '@/components/DeptTree'
  import DoctorSelect from '@/components/DoctorSelect'
  export default {
    name: 'Index',
    components: { DeptTree, DoctorSelect },

    data() {
      return {}
    },
    methods: {
      closseDoctorSelect() {},
      getDept(val) {
        this.$router.push({
          path: '/schedule',
          query: {
            deptCode: this.$Base64.encode(val.code),
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .van-dropdown-menu {
    width: 95%;
    overflow: hidden;
    border-radius: 10px;
    margin: 10px auto auto;

    ::v-deep .van-dropdown-item {
      top: 48px !important;
      width: 95%;
      margin: auto;
      .van-dropdown-item__content {
        height: 95.3vh;
        max-height: none;
      }
    }
  }
</style>
