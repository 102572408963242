<template>
  <van-tree-select
    :items="deptData"
    :active-id.sync="activeId"
    :main-active-index.sync="level2Index"
    height="90vh"
    style="width: 100%"
  >
    <template #content>
      <van-collapse
        v-if="deptData[level2Index] != null"
        v-model="activeNames"
        accordion
      >
        <van-collapse-item
          v-for="(item, index) in deptData[level2Index].children"
          :key="index"
          :title="item.text"
          :name="index"
        >
          <van-cell
            v-for="(item2, index2) in item.children"
            :key="index2"
            class="level3"
            :value="item2.text"
            style="background: #f9fafe; border-bottom: 0"
            @click="changeDept(item2)"
          />
        </van-collapse-item>
      </van-collapse>
    </template>
  </van-tree-select>
</template>

<script>
  import { Toast } from 'vant'
  import { getDeptTree } from '@/api/basic/dept/dept'
  export default {
    name: 'Index',
    props: {
      type: { type: Number, default: () => 1 }, //1.首页 2.科室选择
    },
    data() {
      return {
        activeNames: ['1'],
        activeId: 1,
        level2Index: 0,
        deptData: [],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      //获取科室数据
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getDeptTree()
        this.deptData = data
        Toast.clear()
      },

      //科室选择
      changeDept(item) {
        this.$emit('getDept', item)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-dropdown-menu {
    width: 95%;
    margin: auto;
    overflow: hidden;
    .van-collapse-item {
      background: #000;
      .level3::after {
        border-bottom: 0;
      }
    }
  }

  .van-sidebar-item--select::before {
    background-color: #1691fe;
  }
  .van-sidebar-item--select,
  .van-sidebar-item--select:active {
    color: #1691fe;
  }
</style>
