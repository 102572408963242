<template>
  <div>
    <van-dropdown-item ref="item2" title="选择医生" @close="onClose">
      <van-cell>
        <van-search
          v-model="search.doctorName"
          shape="round"
          show-action
          input-align="center"
          placeholder="输入医生姓名"
          @search="onSearch"
          @cancel="onCancel"
          @clear="onCancel"
          @input="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
        <van-divider />
        <van-cell-group v-if="list.length > 0">
          <van-cell v-for="(item, index) in list" :key="index">
            <van-row @click="toInfo(item)">
              <van-col :span="6">
                {{ item.doctorName }}
              </van-col>
              <van-col :span="8">
                {{ item.title }}
              </van-col>
              <van-col :span="6">
                {{ item.deptName }}
              </van-col>
            </van-row>
          </van-cell>
        </van-cell-group>
        <van-empty v-else description="暂无医生信息，请重新输入" />
      </van-cell>
    </van-dropdown-item>
  </div>
</template>

<script>
  import { getDoctorList } from '@/api/basic/doctor/doctor'
  import moment from 'moment/moment'
  import { Toast } from 'vant'

  export default {
    name: 'Index',
    data() {
      return {
        value: '',
        list: [],
        search: {
          doctorName: '',
        },

        beginTime: '',
        endTime: '',
      }
    },
    created() {
      this.pageInit()
    },

    methods: {
      //初始化
      async pageInit() {
        let data = JSON.parse(sessionStorage.getItem('day'))
        this.day = data.day
        this.beginTime = moment(new Date()).format('yyyy-MM-DD')
        this.endTime = moment(new Date())
          .add(this.day - 1, 'day')
          .format('yyyy-MM-DD')
      },

      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getDoctorList(this.search)
        this.list = data
        Toast.clear()
      },

      toInfo(item) {
        this.$router.push({
          path: '/scheduleDetail',
          query: {
            deptCode: this.$Base64.encode(''),
            doctorCode: this.$Base64.encode(item.doctorCode),
            deptName: this.$Base64.encode(item.deptName),
            doctorName: this.$Base64.encode(item.doctorName),
            beginTime: this.$Base64.encode(this.beginTime),
            endTime: this.$Base64.encode(this.endTime),
            fee: this.$Base64.encode(''),
          },
        })
      },

      setTime(beginTime, endTime) {
        this.beginTime = beginTime
        this.endTime = endTime
      },

      onSearch() {
        this.fetchData()
      },

      onCancel() {
        this.search.doctorName = ''
        this.list = []
      },

      onClose() {
        this.search.doctorName = ''
        this.list = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-divider {
    margin: 5px;
  }
</style>
